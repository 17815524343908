import { useEffect, useState, useCallback, useRef } from "react";

/**
 * @param isOpen // OpenState, 미전달시 children 엘리먼트 그대로 렌더링
 * @param onClose // close함수, 전달시 닫는 애니메이션 구현시 필요
 * @param className // 전체 영역 클래스
 * @param innerClass // 딤영역 제외한 컨텐츠 영역 클래스
 * @param children
 * @param customDim // 커스텀 dim
 * @param outsideClick // 딤 영역 클릭시 닫히게 처리할지 여부
 * @param ani // 애니메이션 재생 여부
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function Popup({
  isOpen,
  onClose,
  className,
  innerClass,
  children,
  customDim,
  outsideClick = false,
  ani = true
}) {
  const [animation, setAnimation] = useState("animate-fadeInUp");
  const modalRef = useRef(null);
  useEffect(() => {
    const clickOutsideCallbackFn = e => {
      e.preventDefault();
      e.stopPropagation();
    };
    if (isOpen !== undefined && isOpen) {
      document.body.style.overflow = "hidden";
    }
    // if (outsideClick) {
    //   document.addEventListener("click", clickOutsideCallbackFn);
    // }
    return () => {
      document.removeEventListener("click", clickOutsideCallbackFn);
      document.body.style.overflow = "auto";
    };
  }, [isOpen, outsideClick]);
  const closePopup = useCallback(() => {
    setAnimation("animate-fadeOutDown");
    setTimeout(() => {
      onClose && onClose();
      setAnimation("animate-fadeInUp");
    }, 200);
  }, [onClose]);
  if (isOpen !== undefined && !isOpen) return null;
  return <div className={`fixed inset-0 z-50 m-auto flex flex-col items-center justify-center ${className}`}>
      {!!customDim ? customDim : <div className="fixed inset-0 z-40 h-full w-full bg-black opacity-80" onClick={closePopup} />}
      <div key="modal" className={`z-50 flex h-auto max-h-[100dvh] w-full items-center overflow-y-auto bg-white ${innerClass} ${ani ? animation : ""}`} ref={modalRef}>
        {typeof children === "function" ? children(closePopup) : children}
      </div>
    </div>;
}