import Logo from "./main/Logo";
export default function LoginHeader({
  openSettingLayer,
  setOpenSettingLayer
}) {
  return <header className={`subHeader sticky left-0 top-0 w-full bg-white z-[12] shadow-login tablet:shadow-none`}>
      {openSettingLayer && <a onClick={() => {
      setOpenSettingLayer(false);
    }} className={`txtHide btnBack absolute w-[56px] h-[56px] block left-0 top-0 bg-ic_arrow_left_ou_b_g90 bg-no-repeat bg-[center_center] tablet:block hidden`}>
          뒤로가기
        </a>}
      <div className={`header flex items-center pr-[56px] pl-[80px] justify-between transition-all duration-[0.3s] h-[112px] tablet:h-[56px] tablet:p-0 tablet:pl-[24px] tablet:h-[56px]`}>
        <Logo className={`tablet:hidden`} />
        {!openSettingLayer && <a onClick={() => {
        setOpenSettingLayer(true);
      }} className={`cursor-pointer txtHide btnMenu hidden tablet:block tablet:absolute tablet:right-0 tablet:top-0 tablet:w-[56px] tablet:h-[56px] 
                      tablet:bg-[url('/images/common/ic_menu_ou_b_g90.svg')] tablet:bg-no-repeat tablet:bg-[center_center]`}>
            상단메뉴
          </a>}
      </div>
    </header>;
}