import { getCookie } from "cookies-next";

export default function MakeHeaders(headers) {
  let newHeaders = {};
  const clientIp = getCookie("x-client-ip");
  // const headerObj = {
  //   Authorization: getAccessToken(),
  //   'Accept-Language': ConvertLocale(locale),
  // }
  //
  // for(let i in type) {
  //   newHeaders = {
  //     ...headers,
  //     [i]: headerObj.i,
  //   }
  // }

  newHeaders = {
    ...headers,
    "X-UA-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    "X-UA-Country-Code": getCookie("country_code") || "",
    "x-forwarded-for": clientIp || "",
  };

  // null값 제거
  newHeaders = Object.fromEntries(Object.entries(newHeaders).filter(([_, v]) => v != null));

  return newHeaders;
}

export const makeHeadersServer = (request, type) => {
  let cookies = request.headers.cookie.split("; ").reduce((prev, current) => {
    const [name, value] = current.split("=");
    prev[name] = value;
    return prev;
  }, {});

  return cookies[type] || "";
};
