import { ConvertLocale } from "../utils/ConvertLocale";
import { getAccessToken } from "../utils/AccessToken";
import { getStorageInfo, STORAGE_KEY } from "../utils/Adsource";
import PaymentsAxios from "@modules/client/PaymentsAxios";
import MakeHeaders from "@modules/utils/makeHeaders";

/**
 * 결제 - Checkout 생성
 * */
export async function newCheckout(locale, body) {
  const res = await fetch(`/api/checkout`, {
    method: "POST",
    headers: MakeHeaders({
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    }),
    body: JSON.stringify(body),
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 결제 - Checkout 조회
 * */
export async function getCheckout(locale, ticketId, utm_source) {
  const res = await fetch(
    `/api/checkout/${ticketId}/basic${utm_source ? `?utm_source=${utm_source}` : ""}`,
    {
      headers: MakeHeaders({
        Authorization: getAccessToken(),
        "Accept-Language": ConvertLocale(locale),
      }),
    },
  );
  return { status: res.status, body: await res.json() };
}

/**
 * 결제 - 예약 확정
 * */
export async function confirmCheckout(locale, ticketId, body) {
  const res = await fetch(`/api/checkout/${ticketId}/confirm`, {
    method: "POST",
    headers: MakeHeaders({
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    }),
    body: JSON.stringify(body),
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 결제 - 예약 취소
 * */
export async function cancelCheckout(locale, userType, body) {
  const res = await fetch(`/api/checkout/cancel`, {
    method: "POST",
    headers: MakeHeaders({
      Authorization: userType === "guest" ? "" : getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    }),
    body: JSON.stringify(body),
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 결제 - 예약 취소 사유 리스트 조회
 */
export async function cancelCheckoutCategory(locale) {
  const res = await fetch(`/api/checkout/cancelcategory`, {
    headers: MakeHeaders({
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    }),
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 결제 - 프로모션 조회(코드)
 * */
export async function getPromotion(locale, ticketId, code) {
  const tbzmeta = getStorageInfo(STORAGE_KEY.T_META_KEY)?.meta_type || "";
  const res = await fetch(`/api/promotion/${code}?ticketId=${ticketId}`, {
    headers: MakeHeaders({
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
      "x-tbz-meta-referer": tbzmeta,
    }),
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 결제 - 예약 확정
 * @param locale
 * @param ticketId
 * @param body
 * @returns {Promise<{body: any, status: number}>}
 */
export async function getAbroadCheckoutInfo(locale, ticketId, body) {
  const res = await fetch(`/api/checkout/${ticketId}/booking`, {
    method: "POST",
    headers: MakeHeaders({
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    }),
    body: JSON.stringify(body),
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 결제 - 결제창 결제 요청 실패
 * @param locale
 * @param ticketId
 * @param body
 * @returns {Promise<{body: any, status: number}>}
 */
export async function sendAbroadCheckoutError(locale, ticketId, body) {
  const res = await fetch(`/api/checkout/${ticketId}/pg-error`, {
    method: "POST",
    headers: MakeHeaders({
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    }),
    body: JSON.stringify(body),
  });
  return { status: res.status, body: await res.json() };
}

/**
 * kcp 결제시 상태 체크 (processing 페이지)
 * @param locale
 * @param ticketId
 * @returns {Promise<{body: any, status: number}>}
 */
export async function getStatusCheck(locale, ticketId) {
  const res = await fetch(`/api/checkout/${ticketId}/status`, {
    headers: MakeHeaders({
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    }),
  });
  return { status: res.status, body: await res.json() };
}

export async function getCheckoutError(locale, ticketId) {
  const res = await PaymentsAxios(`/checkout/${ticketId}/error`, {
    method: "GET",
    headers: MakeHeaders({
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    }),
  });
  return res.data;
}

export async function getExchangedTripcash(locale, type, amount) {
  const response = await fetch(`/api/payment/tripcash/exchange?type=${type}&amount=${amount}`, {
    method: "GET",
    headers: MakeHeaders({
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    }),
  }).then(res => res.json());
  return response.item.exchanged;
}

export async function getCurrencyInfo(locale) {
  const response = await fetch(`/api/payment/tripcash/currency`, {
    method: "GET",
    headers: MakeHeaders({
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    }),
  }).then(res => res.json());
  return response.item;
}
