import MainHeader from "./MainHeader";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
const MediaQuery = dynamic(() => import("react-responsive"), {
  ssr: false
});
export default function MdHeader({
  setIsLogout,
  isApp,
  onOpenSharePopupHandler
}) {
  const router = useRouter();
  return <>
      <MediaQuery minWidth={1025}>
        <MainHeader isShadow={true} className={`tablet:hidden`} setIsLogout={setIsLogout} />
      </MediaQuery>
      {!isApp && <MediaQuery maxWidth={1024}>
          <header className="subHead tp1 hidden tablet:block
                   sticky left-0 top-0 w-full z-[12] bg-white
                   min-h-[56px] text-center">
            <a onClick={() => {
          router.back();
        }} className={`txtHide btnBack absolute w-[56px] h-[56px] block left-0 top-0 bg-ic_arrow_left_ou_b_g90 bg-no-repeat bg-[center_center]`}>
              뒤로가기
            </a>
            <a onClick={onOpenSharePopupHandler} className="txtHide cursor-pointer absolute w-[56px] h-[56px] block right-0 top-0 bg-[url('/images/sub/ic_share__ou_b_g90.svg')] bg-no-repeat bg-[center_center]">
              공유하기
            </a>
          </header>
        </MediaQuery>}
    </>;
}