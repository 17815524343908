import TabBtn from "@components/common/button/TabBtn";
import { useEffect, useRef } from "react";

/**
 * 메인페이지 탭 컴포넌트
 * @param tabArr 탭을 그릴 데이터 배열
 * @param selectTab
 * @param className
 * @param callbacks 콜백 함수
 * @param hover
 * @returns {JSX.Element}
 * @constructor
 */
export default function MainTab({
  tabArr,
  selectTab,
  className,
  callbacks,
  hover = true
}) {
  const selectTabCallbacks = index => {
    callbacks(index, "onTabClick");
  };
  let start;
  let pressed = false;
  const slider = useRef(null);
  const mousedown = e => {
    start = e.x;
    pressed = true;
    if (slider.current) {
      slider.current.style.cursor = "grabbing";
    }
  };
  const mouseup = () => {
    pressed = false;
    if (slider.current) {
      slider.current.style.cursor = "grab";
    }
  };
  const mousemove = e => {
    if (!pressed) return;
    e.preventDefault();
    if (slider.current) {
      slider.current.scrollLeft = slider.current.scrollLeft - (e.x - start);
      start = e.x;
    }
  };
  useEffect(() => {
    if (slider.current) {
      slider.current.addEventListener("mousedown", mousedown);
      slider.current.addEventListener("mousemove", mousemove);
      window.addEventListener("mouseup", mouseup);
    }
    return () => {
      if (slider.current) {
        slider.current.removeEventListener("mousedown", mousedown);
        slider.current.removeEventListener("mousemove", mousemove);
        window.removeEventListener("mouseup", mouseup);
      }
    };
  }, [slider]);
  return <div className={className ? className : "flex items-center justify-center py-1"}>
      <div ref={slider} className="overflow-x-scroll whitespace-nowrap scrollbar-hide">
        {tabArr.map((v, i) => {
        return <TabBtn key={i} onClick={() => {
          selectTabCallbacks(i);
        }} isSelected={selectTab === i} text={v.title} className="ml-2 first:ml-4 last:mr-4 mobile:ml-1.5" hover={hover} />;
      })}
      </div>
    </div>;
}