import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Cookies } from "react-cookie-consent";
import { getDomain } from "@modules/utils/AccessToken";
import { removeAdSource } from "@modules/utils/Adsource";
export default function LangListButton({
  langMap,
  setIsLangMouseOver,
  setIsLangListMouseOver,
  setIsLangPopup
}) {
  const {
    locale
  } = useRouter();
  const {
    i18n
  } = useTranslation("common");
  const router = useRouter();
  const onLanguageChangeHandle = lang => {
    i18n.changeLanguage(lang, () => {
      // Cookies.set("NEXT_LOCALE", lang, { expires: 9999, domain: getDomain() });
      removeAdSource();
      router.replace("/", "/", {
        locale: lang
      });
    });
  };
  return <>
      {Object.keys(langMap).map(key => <div key={key} onClick={() => {
      onLanguageChangeHandle(key);
      if (setIsLangMouseOver) setIsLangMouseOver(false);
      if (setIsLangListMouseOver) setIsLangListMouseOver(false);
      if (setIsLangPopup) setIsLangPopup(false);
    }} className={`SB_14_100_Medium mobile:B_16_100_Medium relative flex h-[40px] cursor-pointer items-center p-[0_42px_0_16px]
                      text-Gray-800
                      hover:bg-Bg-TintWhite mobile:h-[48px] mobile:items-center mobile:pl-[16px]
                      mobile:text-Gray-900 mobile:hover:bg-white
                      ${key === locale && `mobile:text-Primary500`}
                      ${key === locale && `bg-[url('/images/common/ic_check_ou_p50.svg')] bg-[184px_center] bg-no-repeat 
                         mobile:bg-none mobile:after:absolute mobile:after:right-[18px] mobile:after:top-[16px] mobile:after:h-[16px] mobile:after:w-[16px] mobile:after:bg-ic_check_ou_b_p50
                         mobile:after:bg-[0_0] mobile:after:bg-no-repeat mobile:after:content-['']`}`}>
          {langMap[key].title}
        </div>)}
    </>;
}