import React, { useEffect } from "react";
import { useTranslation } from "next-i18next";
import { clearToken, getDomain } from "@modules/utils/AccessToken";
import { useRouter } from "next/router";
import { Cookies } from "react-cookie-consent";
import Popup from "@components/common/popup";
export default function LogoutPopup({
  setIsLogout
}) {
  const {
    t
  } = useTranslation("common");
  const router = useRouter();
  const LogoutHandler = () => {
    clearToken();
    Cookies.set("logout", "Y", {
      expires: 0.1,
      domain: getDomain()
    });
    setIsLogout(false);
    if (router.pathname === "/") {
      router.reload();
    } else {
      router.push("/");
    }
  };
  return <Popup className="max-w-[calc(36rem_-_80px)] p-[36px]" innerClass="rounded-[16px]">
      <div className="flex w-full flex-col items-start text-Gray-900">
        <p className="H5_20_145_Bold px-6 pt-6 text-[18px] font-bold text-Gray-900">
          {t("mypage.alert.logout")}
        </p>
        <hr className="mt-5 w-full text-Gray-50" />
        <div className="flex w-full flex-row">
          <button onClick={() => {
          setIsLogout(false);
        }} className={`H6_18_100_Medium flex h-[56px] w-full items-center justify-center`}>
            {t("mypage.btn.logoutancel")}
          </button>
          <div className="h-[56px] w-[1px] bg-Gray-50"></div>
          <button onClick={LogoutHandler} className={`H6_18_100_Medium flex h-[56px] w-full items-center justify-center text-ErrorRed`}>
            {t("mypage.logout")}
          </button>
        </div>
      </div>
    </Popup>;
}