import MyModal from "@components/layout/header/main/MyModal";
import { useTablet } from "@modules/hooks/useMedia";
import Flight from "@public/images/ttbb/ic_flight_ou_b_g90.svg";
import Plan from "@public/images/ttbb/ic_plan_ou_b_g90.svg";
import Rent from "@public/images/ttbb/ic_rent_ou_b_g90.svg";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { useDomain } from "@modules/hooks/useDomain";
import { IsTTBBApp } from "@modules/utils/TripbtozApp";
export default function MainNav({
  token,
  type
}) {
  const isTablet = useTablet();
  const {
    t
  } = useTranslation("common");
  const router = useRouter();
  const isTTBB = useDomain();
  const homeIcon = isTTBB ? `bg-[url('/images/ttbb/ic_home_ou_b_g90.svg')]` : `bg-[url('/images/common/ic_home_ou_b_g90.svg')]`;
  const myIcon = isTTBB ? `bg-[url('/images/ttbb/ic_my_ou_b_g90.svg')]` : `bg-[url('/images/common/ic_my_ou_b_g90.svg')]`;
  const target = IsTTBBApp() ? "_self" : "_blank";
  const [isMyMouseOver, setIsMyMouseOver] = useState(false);
  const goPage = type => {
    switch (type) {
      case "home":
        return router.push("/");
      case "flight":
        return router.push(process.env.NEXT_PUBLIC_TTBB_FLIGHT_URL);
      case "plan":
        return router.push(process.env.NEXT_PUBLIC_TTBB_PLAN_URL);
      case "rent":
        return router.push(process.env.NEXT_PUBLIC_TTBB_CAR_URL);
      case "my":
        if (token) {
          return router.push("/mypage");
        } else {
          return router.push(`/signin?returnUrl=${encodeURIComponent("/mypage")}`);
        }
      default:
        alert("메뉴 설정이 없습니다.");
    }
  };
  return <>
      {!isTablet && <>
          <button onClick={() => goPage(type)} className={`inline-block h-[72px] min-w-[72px] pt-[40px] text-center ${type === "home" && homeIcon} ${type === "flight" && "bg-[url('/images/ttbb/ic_flight_ou_b_g90.svg')]"} 
            ${type === "rent" && "bg-[url('/images/ttbb/ic_rent_ou_b_g90.svg')]"} 
            ${type === "plan" && "bg-[url('/images/ttbb/ic_plan_ou_b_g90.svg')]"} ${type === "my" && myIcon}
         
          C_12_100_Regular hover:C_12_100_Bold bg-[length:32px_32px] bg-[center_12px] bg-no-repeat 
                        text-Gray-500 hover:rounded-[8px] hover:bg-Bg-OffWhite hover:text-Gray-800 tablet:text-[10px]`} onMouseEnter={() => {
        setIsMyMouseOver(true);
      }} onMouseLeave={() => {
        setIsMyMouseOver(false);
      }}>
            <span className={`${router.pathname === "/" && type === "home" && "font-bold text-Gray-800"} ${router.pathname === "/mypage" && type === "my" && "font-bold text-Gray-800"}`}>
              {t(`header.btn.${type}`)}
            </span>
          </button>
          <MyModal token={token} isMyMouseOver={isMyMouseOver} />
        </>}

      {isTablet && <div className="flex cursor-pointer justify-center">
          {type === "flight" && <button onClick={() => goPage(type)} className="flex flex-col items-center justify-center">
              <Flight className="h-[24px] w-[24px]" />
              <span className="MM_10_400_Regular  text-Gray-500 tablet:m-0 mobile:text-[10px]">
                {t(`header.btn.flight`)}
              </span>
            </button>}
          {type === "rent" && <button onClick={() => goPage(type)} className="flex flex-col items-center justify-center">
              <Rent className="h-[24px] w-[24px]" />
              <span className="MM_10_400_Regular  text-Gray-500 tablet:m-0 mobile:text-[10px]">
                {t(`header.btn.rent`)}
              </span>
            </button>}
          {type === "plan" && <button onClick={() => goPage(type)} className="flex flex-col items-center justify-center">
              <Plan className="h-[24px] w-[24px]" />
              <span className="MM_10_400_Regular  text-Gray-500 tablet:m-0 mobile:text-[10px]">
                {t(`header.btn.plan`)}
              </span>
            </button>}
        </div>}
    </>;
}