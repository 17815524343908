import { useDomain } from "@modules/hooks/useDomain";

/**
 * 탭 버튼 컴포넌트
 * @param onClick
 * @param isSelected
 * @param text
 * @param className
 * @param hover
 * @returns {JSX.Element}
 * @constructor
 */
export default function TabBtn({
  onClick,
  isSelected,
  text,
  className,
  hover = true
}) {
  const isTTBB = useDomain();
  return <button onClick={onClick} className={`inline-flex h-10 items-center justify-center rounded-[100px] px-5 mobile:h-8 mobile:px-[14px] 
                 ${isSelected ? isTTBB ? "B_16_100_Bold mobile:SB_14_100_Bold border-0 bg-Gray-900 text-white" : "B_16_100_Bold mobile:SB_14_100_Bold border border-solid border-Gray-900 bg-Gray-900 text-white" : isTTBB ? "B_16_100_Medium mobile:SB_14_100_Medium border-0 bg-Gray-100 text-Gray-600" : "B_16_100_Medium mobile:SB_14_100_Medium border border-solid border-Gray-100 bg-white text-Gray-400"} ${className} ${hover ? "hover:border-Gray-900 hover:bg-Gray-900 hover:text-white" : ""}
                `}>
      {text}
    </button>;
}