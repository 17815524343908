import { ConvertLocale } from '../utils/ConvertLocale';
import { getAccessToken } from '../utils/AccessToken';
import MakeHeaders from '@modules/utils/makeHeaders';

/**
 * 로그인
 * {
 * 	"email": "example@tripbtoz.com",
 * 	"password": "example"
 * }
 * */
export async function signin(locale, body) {
  const res = await fetch(`/api/auth/signin`, {
    method: 'POST',
    headers: MakeHeaders({
      'Accept-Language': ConvertLocale(locale),
    }),
    body: JSON.stringify(body),
  });
  return { status: res.status, body: await res.json() };
}

export async function tokenRefresh(locale, body) {
  const res = await fetch(`/api/auth/token/refresh`, {
    method: 'POST',
    headers: MakeHeaders({
      'Accept-Language': ConvertLocale(locale),
    }),
    body: JSON.stringify(body),
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 회원가입
 * {
 *   "email": "test_@tripbtoz.com",
 *   "password": "qwer1234!!",
 *   "name": "TEST USER",
 *   "birthday": "1980-01-01",
 *   "nickname": "",
 *   "terms_accepted": true,
 *   "location_policy": true,
 *   "ad_policy_accepted": true
 * }
 * */
export async function signup(locale, body) {
  const res = await fetch(`/api/auth/signup`, {
    method: 'POST',
    headers: MakeHeaders({
      'Accept-Language': ConvertLocale(locale),
    }),
    body: JSON.stringify(body),
  });
  return { status: res.status, body: await res.json() };
}

/**
 * SNS 약관동의 여부 확인
 * provider : SNS 로그인 종류
 * - kakao
 * - naver
 * - facebook
 * - google
 * - apple
 * id : 1950956091
 * */
export async function policies(locale, provider, id) {
  const res = await fetch(`/api/auth/${provider}/policies?id=${id}`, {
    headers: MakeHeaders({
      Authorization: getAccessToken(),
      'Accept-Language': ConvertLocale(locale),
    }),
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 중복 이메일 체크
 * email : test@tripbtoz.com
 * */
export async function validationEmail(locale, email) {
  const res = await fetch(`/api/auth/validation/email?email=${email}`, {
    headers: MakeHeaders({
      'Accept-Language': ConvertLocale(locale),
    }),
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 비밀번호 변경 이메일 유효성 검사
 * */
export async function passwordValidationEmail(locale, email) {
  const res = await fetch(
    `/api/auth/password/validation/email?email=${email}`,
    {
      headers: MakeHeaders({
        'Accept-Language': ConvertLocale(locale),
      }),
    },
  );
  return { status: res.status, body: await res.json() };
}

/**
 *
 * 비밀번호 변경 티켓 유효성 검사
 * */
export async function passwordValidationTicket(locale, ticket) {
  const res = await fetch(`/api/auth/password/validation/${ticket}`, {
    headers: MakeHeaders({
      'Accept-Language': ConvertLocale(locale),
    }),
  });
  return { status: res.status, body: await res.json() };
}

/**
 *
 * 비밀번호 변경 (변경 이메일 인입)
 * */
export async function passwordTicket(locale, ticket, body) {
  const res = await fetch(`/api/auth/password/${ticket}`, {
    method: 'POST',
    headers: MakeHeaders({
      'Accept-Language': ConvertLocale(locale),
    }),
    body: JSON.stringify(body),
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 휴대폰 인증번호 요청
 * */
export async function validationIdentity(locale, body) {
  const res = await fetch(`/api/auth/verify`, {
    method: 'POST',
    headers: MakeHeaders({
      Authorization: getAccessToken(),
      'Accept-Language': ConvertLocale(locale),
    }),
    body: JSON.stringify(body),
  });
  return { status: res.status, body: await res.json() };
}

export async function validationIdentityComplete(locale, body) {
  const res = await fetch(`/api/auth/verify/complete`, {
    method: 'POST',
    headers: MakeHeaders({
      Authorization: getAccessToken(),
      'Accept-Language': ConvertLocale(locale),
    }),
    body: JSON.stringify(body),
  });
  return { status: res.status, body: await res.json() };
}
