import React, { useEffect } from "react";
import { useDomain } from "@modules/hooks/useDomain";
export default function Toast({
  toastText,
  setToastState,
  bottomClass
}) {
  const isTTBB = useDomain();
  const toastBg = isTTBB ? "bg-Gray-800" : "bg-SlateBlue";
  useEffect(() => {
    setTimeout(function () {
      setToastState(false);
    }, 1500);
  });
  return <div className={`fixed bottom-4 z-50 flex w-full justify-center tablet:bottom-[66px] tablet:mx-[12px] tablet:my-0 tablet:w-[calc(100%_-_24px)]
                  ${bottomClass || ""}`}>
      <div className={`w-[336px] rounded-[4px] shadow-toast ${toastBg}`}>
        <p className="SB_14_150_Medium px-5 py-4 text-center text-white" dangerouslySetInnerHTML={{
        __html: toastText
      }} />
      </div>
    </div>;
}