import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Cookies } from 'react-cookie-consent';
import { useTranslation } from 'next-i18next';
import styles from './HomeBar.module.css';
export default function HomeBar({
  isHomeBarFixed
}) {
  const router = useRouter();
  const {
    t
  } = useTranslation('common');
  const [token, setToken] = useState(null);
  useEffect(() => {
    setToken(Cookies.get('access_token'));
  }, []);
  return <div id="homeBar" className={`homeBar left-0 bottom-0 z-[10] hidden h-[56px] w-full bg-black transition-all delay-[0.3s] tablet:block
                  ${isHomeBarFixed ? 'fixed' : 'relative'}`}>
      <ul className={styles.homeBar}>
        <li className={`inline-block min-w-[30%] text-Gray-600`}>
          <Link href="/" className={`${router.pathname === '/' ? styles.homeOn : styles.home}`}>
            {t('header.btn.home')}
          </Link>
        </li>
        <li className={`inline-block min-w-[30%] text-Gray-600`}>
          <Link href="/search/mobile" className={`${router.pathname === '/search/mobile' ? styles.searchOn : styles.search}`}>
            {t('footer.btn.terms.service')}
          </Link>
        </li>
        <li className={`inline-block min-w-[30%] text-Gray-600`}>
          <Link href={token ? '/mypage' : '/signin'} className={`${router.pathname === '/mypage' || router.pathname === '/signin' ? styles.myOn : styles.my}`}>
            {t('header.btn.my')}
          </Link>
        </li>
      </ul>
    </div>;
}