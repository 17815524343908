import IcTop from "@public/images/common/ic_top_ou_g90.svg";
import IcTopTTBB from "@public/images/ttbb/ic_top_ou_g90.svg";
import { useCallback, useEffect, useState } from "react";
import { getValueByDomain } from "@modules/utils/getValuewithDomain";
export default function TopBtn() {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollOpa, setScrollOpa] = useState(0);
  const onScroll = useCallback(event => {
    const {
      scrollY
    } = window;
    setScrollTop(scrollY);
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", onScroll, {
      passive: true
    });
    return () => {
      window.removeEventListener("scroll", onScroll, {
        passive: true
      });
    };
  }, []);
  useEffect(() => {
    const opacity = Math.round(scrollTop / 42) / 10;
    setScrollOpa(opacity > 1 ? 1 : opacity);
  }, [scrollTop]);
  const moveTop = () => {
    window.scrollTo(0, 0);
  };
  return <div className="mobile:hidden fixed flex justify-center items-center overflow-hidden w-[56px] h-[56px] gap-0.5 p-4
                 rounded-[36px] bg-white border border-Gray-100 shadow-[0_4px_10px_0_rgba(0,0,0,0.1)]
                 bottom-[128px] right-[56px] z-[2] cursor-pointer" style={{
    opacity: scrollOpa
  }} onClick={moveTop}>
      <div className="flex flex-col justify-center items-center flex-grow-0 flex-shrink-0 relative overflow-hidden">
        {getValueByDomain(<IcTop />, <IcTopTTBB />)}
      </div>
    </div>;
}