import Script from "next/script";
export default function TTBBChannelTalk() {
  return <Script id="bootScript" strategy="lazyOnload" dangerouslySetInnerHTML={{
    __html: `
            (function(){var w=window;if(w.ChannelIO){return w.console.error("ChannelIO script included twice.");}var ch=function(){ch.c(arguments);};ch.q=[];ch.c=function(args){ch.q.push(args);};w.ChannelIO=ch;function l(){if(w.ChannelIOInitialized){return;}w.ChannelIOInitialized=true;var s=document.createElement("script");s.type="text/javascript";s.defer=true;s.src="https://cdn.channel.io/plugin/ch-plugin-web.js";var x=document.getElementsByTagName("script")[0];if(x.parentNode){x.parentNode.insertBefore(s,x);}}if(document.readyState==="complete"){l();}else{w.addEventListener("DOMContentLoaded",l);w.addEventListener("load",l);}})();
            ChannelIO('boot', {
              "pluginKey": "69f9a8df-8766-4da1-907a-d5ca10b0573d",
              "customLauncherSelector" : "#channeltalk-open",
              "zIndex": 1000,
            });
          `
  }} defer={true}></Script>;
}