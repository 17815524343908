import { useRouter } from "next/router";
import Logo from "@components/layout/header/main/Logo";
export default function VerifyHeader({
  isApp,
  title
}) {
  const router = useRouter();
  if (isApp) return;
  return <header className="tablet:shadow-none z-10 w-full bg-white shadow-login tablet:fixed tablet:left-0 tablet:top-0">
      <div className={`relative flex h-[112px] items-center justify-between pl-[80px] pr-[56px] transition-all duration-[0.3s] tablet:h-[56px] tablet:p-0 `}>
        <Logo className={`tablet:hidden`} />
        <a onClick={() => router.back()} className={`txtHide btnCloseHd absolute left-0 top-0 hidden h-[56px] w-[56px] flex-none cursor-pointer 
                    bg-ic_arrow_left_ou_b_g90 bg-[center_center] bg-no-repeat tablet:block`}>
          Close
        </a>
        <h1 className="H6_18_100_Bold hidden grow text-center leading-[56px] tablet:block">
          {title}
        </h1>
      </div>
    </header>;
}