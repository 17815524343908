import React from "react";
import Image from "next/image";
import { useImgPath } from "@modules/hooks/useImgPath";
import { getValueByDomain } from "@modules/utils/getValuewithDomain";
export default function KayakHeader() {
  const type = useImgPath();
  const LOGO_URL = `/images/${type}/img_logo_main.svg`;
  return <header className={'flex flex-column items-center pr-[56px] pl-[80px] tablet:pl-[24px] transition-all duration-[0.3s] h-[112px] tablet:h-[56px]'}>
      <div className={`h-full ${getValueByDomain('w-[174px] tablet:w-[116px] mobile:w-[87px] tablet:h-[32px] mobile:h-[24px] ', 'h-[56px] tablet:h-[28.8px] tablet:w-[28.8px]  w-[56px] ')}} relative`}>
        <Image src={LOGO_URL} alt='logo' sizes='(max-width: 768px) [174px]' fill priority />
      </div>
    </header>;
}