import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { clearToken, getAccessToken } from "@modules/utils/AccessToken";
import { Commify } from "@modules/utils/Commify";
import { getCrewMainSimple } from "@modules/api/Crew";
import { useAtom } from "jotai/index";
import { mainPageVer2Atom } from "@store/global";
export default function MyModal({
  token,
  isMyMouseOver
}) {
  const router = useRouter();
  const {
    t
  } = useTranslation("common");
  const {
    locale
  } = useRouter();
  const [myInfo, setMyInfo] = useState();
  const [isMyListMouseOver, setIsMyListMouseOver] = useState(false);
  const [isMyListOpen, setIsMyListOpen] = useState(false);
  const [mainPageVer2] = useAtom(mainPageVer2Atom); // 신규 메인페이지 분기

  useEffect(() => {
    if (!!token && !!getAccessToken()) {
      getCrewMainSimple(locale).then(data => setMyInfo(data.body.item));
    }
  }, [locale, token]);
  useEffect(() => {
    if (isMyMouseOver || isMyListMouseOver) {
      setIsMyListOpen(true);
    } else {
      setIsMyListOpen(false);
    }
  }, [isMyMouseOver, isMyListMouseOver]);
  const LogoutHandler = () => {
    clearToken();
    if (router.asPath === "/") {
      router.reload();
    } else {
      router.push("/");
    }
  };
  return <>
      {isMyListOpen && token && <div className={`absolute h-full w-[212px] ${mainPageVer2 ? "right-0 top-[35px]" : "right-[56px] top-[120px]"}`} onMouseEnter={() => {
      setIsMyListMouseOver(true);
    }} onMouseLeave={() => {
      setIsMyListMouseOver(false);
    }}>
          <div className="layerMy absolute top-[12px] z-[4] w-[212px] rounded-[12px] bg-white shadow-[0px_2px_20px_rgba(0,0,0,0.08)]">
            <dl className="mt-[14px] border-b border-solid border-b-Gray-50 p-[16px] text-left" onClick={() => {
          router.push({
            pathname: "/mypage/tripcash"
          });
        }}>
              <dt className="SB_14_100_Medium pb-[8px] text-Gray-400">{t("pay.info.tripcash")}</dt>
              <dd className="SB_14_100_Regular text-Gray-900">
                <strong className="H5_20_100_Bold">{Commify(myInfo?.tripcash ?? 0)} T</strong>
              </dd>
            </dl>
            <ul className="px-0 pb-[14px] pt-0">
              <li className="SB_14_100_Medium px-[16px] hover:bg-Bg-TintWhite">
                <Link href={{
              pathname: "/mypage/coupon"
            }} className="relative flex h-[40px] items-center justify-between bg-[url('/images/sub/ic_arrow_right_ou_th_g40.svg')] bg-[right_center] bg-no-repeat pr-[15px] text-Gray-800">
                  {t("mypage.btn.menu.coupon")}
                  <span className="SB_14_100_Bold">{Commify(myInfo?.coupon ?? 0)}</span>
                </Link>
              </li>
              <li className="SB_14_100_Medium px-[16px] hover:bg-Bg-TintWhite">
                <Link href={{
              pathname: "/mypage/reserve"
            }} className="relative flex h-[40px] items-center justify-between text-Gray-800">
                  {t("mypage.btn.menu.bookings")}
                  {myInfo?.crew_pick && <span className="SC_11_100_Bold flex h-5 items-center justify-center rounded-[10px] bg-SlateBlue px-2 py-1 text-white">
                      {"여행 끝! 혜택보기"}
                    </span>}
                </Link>
              </li>
              <li className="SB_14_100_Medium px-[16px] hover:bg-Bg-TintWhite">
                <Link href={{
              pathname: "/mypage/profile"
            }} className="relative flex h-[40px] items-center text-Gray-800">
                  {t("mypage.btn.profile.edit")}
                </Link>
              </li>
              <li className="SB_14_100_Medium px-[16px] hover:bg-Bg-TintWhite">
                <a onClick={LogoutHandler} className="relative flex h-[40px] cursor-pointer items-center text-Gray-500">
                  {t("header.btn.logout")}
                </a>
              </li>
            </ul>
          </div>
        </div>}
    </>;
}