import axios from "axios";
import MakeHeaders from "@modules/utils/makeHeaders";
// import { captureException } from "@sentry/nextjs";

export const axiosSetting = {
  scheme: process.env.NEXT_PUBLIC_PAYMENTS_BASE_SCHEME,
  host: process.env.NEXT_PUBLIC_PAYMENTS_BASE_HOST,
  port: process.env.NEXT_PUBLIC_PAYMENTS_BASE_PORT,
  server() {
    return `${this.scheme ? `${this.scheme}:` : ""}//${this.host}${
      this.port ? `:${this.port}` : ""
    }`;
  },
};
// console.log(`PAYMENTS URL = ${axiosSetting.server()}`);
const isTTBB = process.env.NEXT_PUBLIC_DOMAIN.includes("ttbb");
export const ApiAxios = axios.create({
  baseURL: axiosSetting.server(),
  headers: MakeHeaders({
    "Content-Type": "application/json",
    "x-tripbtoz-channel": isTTBB ? "TTBB" : "TBZ",
  }),
});
ApiAxios.interceptors.response.use(
  res => res,
  async err => {
    const {
      config,
      response: { status },
    } = err;
    // captureException(
    //   {
    //     name: "PaymentsAxios_ERROR",
    //     message: err.code,
    //   },
    //   {
    //     ...config,
    //   },
    // );
    return Promise.reject(err);
  },
);
export default ApiAxios;
