import Image from "next/image";
import MainTab from "@components/common/tab/MainTab";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useMobile } from "@modules/hooks/useMedia";
import { IsTripbtozApp } from "@modules/utils/TripbtozApp";
import ChannelTalk from "@components/script/ChannelTalk";
export const Pledge = ({
  popupMode
}) => {
  const {
    t
  } = useTranslation("common");
  const [infoCard, setInfoCard] = useState([]);
  const [selectTab, setSelectTab] = useState(0);
  const sectionRefs = useRef([]);
  const tabRatio = useRef([]);
  const intersectionFocusBlock = useRef(false);
  const router = useRouter();
  const isMobile = useMobile();
  const popupContainer = useRef(null);

  /**
   * 쿼리에 따른 페이지 진입시 스크롤 이동 > 탭 처리로 변경
   */
  useEffect(() => {
    if (!router.isReady) return;
    const section = router.query.section;
    if (section !== undefined && sectionRefs.current) {
      const index = parseInt(section, 10);
      // if (index >= 0 && index < sectionRefs.current.length) {
      //   const scrollOffset = IsTripbtozApp() ? 40 : 96;
      //   const sectionTop = sectionRefs.current[index].getBoundingClientRect().top;
      //   const position = sectionTop + window.pageYOffset - scrollOffset;
      //   window.scrollTo({
      //     top: position,
      //     behavior: "smooth",
      //   });
      // }
      setSelectTab(index);
      if (!popupMode && window?.ChannelIO) {
        window.ChannelIO("hideChannelButton");
      }
    }
  }, [router.isReady, sectionRefs.current, infoCard, popupMode]);
  useEffect(() => {
    setInfoCard([{
      icon: "/images/common/ic_security_ou_multi.png",
      infoText: t("pledge.infocard.security.infotext"),
      title: t("pledge.infocard.security.title"),
      contentsTitle: t("pledge.infocard.security.contents.title"),
      contents: [{
        title: "",
        description: t("pledge.infocard.security.contents.one.description"),
        linkText: t("setting.privacy.policy")
      }]
    }, {
      icon: "/images/common/ic_safety payment_ou_multi.png",
      infoText: t("pledge.infocard.payment.infotext"),
      title: t("pledge.infocard.payment.title"),
      contentsTitle: t("pledge.infocard.payment.contents.title"),
      contents: [{
        title: "",
        description: t("pledge.infocard.payment.contents.one.description")
      }]
    }, {
      icon: "/images/common/ic_profit_ou_multi.png",
      infoText: t("pledge.infocard.profit.infotext"),
      title: t("pledge.infocard.profit.title"),
      contentsTitle: t("pledge.infocard.profit.contents.title"),
      contents: [{
        title: t("pledge.infocard.profit.contents.one.title"),
        description: t("pledge.infocard.profit.contents.one.description")
      }, {
        title: t("pledge.infocard.profit.contents.two.title"),
        description: t("pledge.infocard.profit.contents.two.description") + "\n" + t("pledge.infocard.profit.contents.two.description2"),
        linkText: t("setting.terms.tripcash.policy")
      }, {
        title: t("pledge.infocard.profit.contents.three.title"),
        description: t("pledge.infocard.profit.contents.three.description") + t("pledge.infocard.profit.contents.three.description2"),
        linkText: t("setting.terms.ranking.service")
      }, {
        title: t("pledge.infocard.profit.contents.four.title"),
        description: t("pledge.infocard.profit.contents.four.description"),
        linkText: t("pledge.infocard.profit.contents.four.description.link")
      }]
    }, {
      icon: "/images/common/ic_cs_ou_multi.png",
      infoText: t("pledge.infocard.cs.infotext"),
      title: t("pledge.infocard.cs.title"),
      contentsTitle: t("pledge.infocard.cs.contents.title"),
      contents: [{
        title: "",
        description: t("pledge.infocard.cs.contents.one.description"),
        linkText: t("setting.cmcenter.frequently.asked")
      }, {
        title: t("pledge.infocard.cs.contents.two.title"),
        description: t("pledge.infocard.cs.contents.two.description")
      }]
    }]);
  }, []);
  const observerCallback = (entries, observer) => {
    entries.forEach(entry => {
      const index = sectionRefs.current.indexOf(entry.target);
      tabRatio.current[index] = entry.intersectionRatio;
      if (!intersectionFocusBlock.current) {
        let maxRatioIndex = 0;
        for (let i = 1; i < tabRatio.current.length; i++) {
          if (tabRatio.current[i] > tabRatio.current[maxRatioIndex]) {
            maxRatioIndex = i;
          }
        }
        setSelectTab(maxRatioIndex);
      }
    });
  };

  // useEffect(() => {
  //   const observer = new IntersectionObserver(observerCallback, {
  //     rootMargin: IsTripbtozApp() ? "40px" : "56px",
  //     threshold: [0.1, 0.25, 0.5, 0.75, 1.0],
  //   });
  //
  //   sectionRefs.current.forEach(ref => {
  //     if (ref) observer.observe(ref);
  //   });
  //
  //   return () => {
  //     sectionRefs?.current?.forEach(ref => {
  //       if (ref) observer.unobserve(ref);
  //     });
  //   };
  // }, [infoCard]);

  const tabCallbacks = index => {
    setSelectTab(index);
    intersectionFocusBlock.current = true;
    const scrollOffset = IsTripbtozApp() ? 40 : 96;
    const sectionTop = sectionRefs.current[index].getBoundingClientRect().top;
    if (popupMode) {
      setTimeout(() => {
        // sectionRefs.current[index].scrollIntoView({
        //   behavior: "smooth",
        // });
        document.getElementsByClassName("z-50 flex h-auto max-h-[90vh] w-full items-center overflow-y-auto bg-white rounded-[20px] flex-col scrollbar-hide")[0].scrollTo({
          top: 426,
          behavior: "smooth"
        });
      }, 50);
    } else {
      window.scrollTo({
        // top: sectionTop + window.pageYOffset - scrollOffset,
        top: 426,
        behavior: "smooth"
      });
    }
    setTimeout(() => {
      intersectionFocusBlock.current = false;
    }, 1000);
  };
  const handleLinkTextClick = text => {
    const sendLink = url => {
      if (IsTripbtozApp()) {
        location.href = `tripbtoz://webview?url=${encodeURIComponent(process.env.NEXT_PUBLIC_CURRENT_DOMAIN + url.slice(1))}&title=${encodeURIComponent(text)}&back=true&remove=false`;
      } else {
        // router.push(url);
        window.open(url, "_blank");
      }
    };
    switch (text) {
      case t("setting.privacy.policy"):
        sendLink("/terms/privacy");
        break;
      case t("setting.terms.tripcash.policy"):
        sendLink("/terms/tripcash");
        break;
      case t("setting.terms.ranking.service"):
        sendLink("/terms/ranking");
        break;
      case t("pledge.infocard.profit.contents.four.description.link"):
        sendLink("/md/play_intro_kr");
        break;
      case t("setting.cmcenter.frequently.asked"):
        // sendLink("/cmcenter/qna");
        if (IsTripbtozApp()) {
          location.href = `tripbtoz://faq?remove=false`;
        } else {
          window.open("/cmcenter/qna", "_blank");
        }
        break;
      case t("setting.inquiry.email"):
        IsTripbtozApp() ? location.href = "mailto:cs@tripbtoz.com" : window.open("mailto:cs@tripbtoz.com");
        break;
      case t("setting.inquiry.kakao.talk"):
        window.open("https://pf.kakao.com/_LRRxlxl", "_blank");
        break;
      case "전화":
        IsTripbtozApp() ? location.href = "tel:02-711-6880" : window.open("tel:02-711-6880");
        break;
      case "챗봇":
        const shadow = document.querySelector("#ch-plugin-entry div").shadowRoot;
        const button = shadow.querySelector("#ch-plugin-launcher");
        if (button) button.click();
        break;
    }
  };
  return <>
      <div ref={popupContainer} className={`${popupMode ? "relative mb-[600px] h-[880px] w-full px-8 pb-6" : "pb-4"} font-pretendard`}>
        {/*상단 인포 영역*/}
        <div className={`flex flex-col bg-Primary100 px-6 pb-6 pt-10 ${popupMode ? "items-center rounded-xl" : ""}`}>
          <div className="H4_24_100_Bold mb-2 text-Gray-900">{t("pledge.info.title")}</div>
          {t("pledge.info.description").replace(/\\n/g, "\n").split("\n").map((v, i) => <span key={i}>{v}</span>)}

          <div className="mt-4 flex flex-col gap-2">
            {infoCard.map((v, i) => <div key={i} className={`flex flex-row items-center gap-4 rounded-xl border border-Gray-50 bg-white px-4 py-2 ${popupMode && "w-[300px]"}`}>
                <div className="flex h-[44px] w-[44px] items-center justify-center rounded-full border border-Gray-50">
                  <Image src={v.icon} alt="iconImg" width={24} height={24} />
                </div>
                <span className="B_16_100_Medium text-Gray-900">{v.infoText}</span>
              </div>)}
          </div>
        </div>

        {/*탭 영역*/}
        <MainTab tabArr={infoCard} selectTab={selectTab} className={`sticky flex items-center border-b border-Bg-LightGray bg-white py-2 ${popupMode ? "top-[68px]" : IsTripbtozApp() ? "top-0" : "top-[56px]"}`} callbacks={tabCallbacks} hover={false} />

        {/*컨텐츠 영역*/}
        {infoCard.map((v, i) => {
        return <div key={i} ref={el => sectionRefs.current[i] = el} className={`mt-4 flex flex-col gap-2 px-4 py-2 ${i === selectTab ? "block" : "hidden"}`}>
              <span className="B_16_145_Bold">{v.contentsTitle}</span>
              <div className="">
                {v.contents.map((value, index) => <React.Fragment key={index}>
                    {value.title && <span className="SB_14_160_Bold block h-[38px] px-1.5 py-2 text-Gray-900">
                        {value.title}
                      </span>}
                    <ul key={index} className="pl-4">
                      {value.description.replace(/\\n/g, "\n").split("\n").map((innerValue, inerIndex, array) => {
                  const descriptionParts = innerValue.split(value.linkText);
                  const foundLink = array.slice(0, inerIndex).join(" ").split(value.linkText).length > 1;
                  const isPrefixed = /^(a|b|c|d)\./.test(innerValue.trim());
                  if (descriptionParts.length > 1 && !foundLink) {
                    return <li className={`${isPrefixed ? "" : "list-disc"} SB_14_160_Regular text-Gray-700 last:pb-1`} key={inerIndex}>
                                {descriptionParts[0]}

                                {"linkText" in value && value.linkText && <button className="text-Primary600 underline" onClick={() => {
                        handleLinkTextClick(value.linkText);
                      }}>
                                    {value.linkText}
                                  </button>}

                                {descriptionParts[1]}
                              </li>;
                  } else {
                    return <li className={`${isPrefixed ? "" : "list-disc"} SB_14_160_Regular text-Gray-700 last:pb-1`} key={inerIndex}>
                                {innerValue}
                              </li>;
                  }
                })}
                    </ul>
                  </React.Fragment>)}
              </div>
            </div>;
      })}

        {selectTab === 3 && <div className="flex flex-row gap-1 px-4">
            {[{
          text: t("setting.inquiry.email"),
          visible: true
        }, {
          text: t("setting.inquiry.kakao.talk"),
          visible: IsTripbtozApp() || isMobile
        }, {
          text: t("pledge.label.phone"),
          visible: IsTripbtozApp() || isMobile
        }, {
          text: t("pledge.label.chatbot"),
          visible: !IsTripbtozApp()
        }].map((v, i) => {
          return v.visible && <div key={i} className="C_12_100_Bold cursor-pointer rounded-[50px] bg-Primary70 px-4 py-2 text-Primary600" onClick={() => {
            handleLinkTextClick(v.text);
          }}>
                    {v.text}
                  </div>;
        })}
          </div>}
      </div>
    </>;
};