import Logo from "./main/Logo";
import { useRouter } from "next/router";
export default function SignupHeader() {
  const router = useRouter();
  const icon = router.query.provider ? "tablet:bg-[url('/images/common/ic_arrow_left_ou_b_g90.svg')]" : "tablet:bg-[url('/images/common/ic_cancel_ou_b_g90.svg')]";
  return <header className={`subHeader tablet:shadow-none sticky left-0 top-0 z-[12] w-full bg-white shadow-login`}>
      <div className={`header flex h-[112px] items-center justify-between pl-[80px] pr-[56px] transition-all duration-[0.3s] tablet:h-[56px] tablet:p-0 tablet:pl-[24px]`}>
        <Logo className={`tablet:hidden`} />
        <a onClick={() => {
        router.back();
      }} className={`txtHide btnCloseHd hidden tablet:absolute tablet:left-0 tablet:top-0 tablet:block tablet:h-[56px] tablet:w-[56px] 
                      ${icon} tablet:bg-[center_center] tablet:bg-no-repeat`}>
          Close
        </a>
      </div>
    </header>;
}