import { useCallback, useEffect, useState } from "react";
import { throttle } from "lodash-es";

export function useScroll() {
  const [scrollY, setScrollY] = useState(0);

  const onScroll = useCallback(
    throttle(() => {
      const { scrollY } = window;
      setScrollY(scrollY);
    }, 100),
    [],
  );

  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", onScroll, { passive: true });
    };
  }, []);

  return scrollY;
}
