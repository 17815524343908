import { useRouter } from "next/router";
import { IsTripbtozApp } from "@modules/utils/TripbtozApp";
export default function CheckOutHeader() {
  const router = useRouter();
  return <>
      <header className="subHead tp1 hidden tablet:block
                   sticky left-0 top-0 w-full z-[12] bg-white
                   min-h-[56px] text-center">
        <a onClick={e => {
        e.preventDefault();
        if (IsTripbtozApp()) {
          location.href = "tripbtoz://back";
          return;
        }
        router.back();
      }} className={`txtHide btnBack absolute w-[56px] h-[56px] block left-0 top-0 bg-ic_arrow_left_ou_b_g90 bg-no-repeat bg-[center_center]`}>
          뒤로가기
        </a>
        <h1 className="H6_18_100_Bold leading-[56px]">예약</h1>
      </header>
    </>;
}