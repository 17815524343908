import MainHeader from "./MainHeader";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useDomain } from "@modules/hooks/useDomain";
export default function ProfileHeader({
  setIsWithdrawPopup,
  setIsLogout
}) {
  const router = useRouter();
  const isTTBB = useDomain();
  // 다국어
  const {
    t
  } = useTranslation("common");
  return <>
      <MainHeader isShadow={true} className={`tablet:hidden`} setIsLogout={setIsLogout} />
      <header className="subHead tp1 sticky left-0
                   top-0 z-[12] hidden min-h-[56px] w-full bg-white
                   text-center tablet:block">
        <a onClick={() => {
        router.back();
      }} className={`txtHide btnBack absolute left-0 top-0 block h-[56px] w-[56px] bg-ic_arrow_left_ou_b_g90 bg-[center_center] bg-no-repeat`}>
          뒤로가기
        </a>
        <div className="btnOutM absolute right-0 top-0 hidden tablet:block">
          <a onClick={() => {
          if (isTTBB) {
            router.push("/mypage/withdraw");
          } else {
            setIsWithdrawPopup(true);
          }
        }} className="SB_14_100_Medium flex h-[56px] cursor-pointer items-center px-[20px] py-0 text-Gray-300">
            {t("mypage.member.withdrawal.txt.leave.now")}
          </a>
        </div>
      </header>
    </>;
}