import LangListButton from "@components/layout/header/main/LangListButton";
import { useEffect, useState } from "react";
export default function LanguageMap({
  locale,
  setIsLangPopup
}) {
  const [isLangMouseOver, setIsLangMouseOver] = useState(false);
  const [isLangListMouseOver, setIsLangListMouseOver] = useState(false);
  const [isLangListOpen, setIsLangListOpen] = useState(false);
  const langMap = {
    "ko-KR": {
      title: "한국어 / KRW",
      bg: "before:bg-[url('/images/common/img_flag_korea.svg')]"
    },
    "en-US": {
      title: "English / USD",
      bg: "before:bg-[url('/images/common/img_flag_us.svg')]"
    }
  };
  useEffect(() => {
    if (isLangMouseOver || isLangListMouseOver) {
      setIsLangListOpen(true);
    } else {
      setIsLangListOpen(false);
    }
  }, [isLangMouseOver, isLangListMouseOver]);
  return <>
      <div className="langDiv relative ml-1.5 cursor-pointer" onMouseEnter={e => {
      setIsLangMouseOver(true);
    }} onMouseLeave={e => {
      setIsLangMouseOver(false);
    }}>
        <div className={`SB_14_100_Regular before:content relative flex h-[40px] items-center justify-center
                     p-[0_12px_0_36px] before:absolute
                     before:left-[12px] before:top-[13px] before:h-[14px] before:w-[20px] before:bg-[0_0] before:bg-no-repeat hover:rounded-[8px] hover:bg-Bg-TintWhite
                     ${langMap[locale] && langMap[locale].bg}`}>
          {langMap[locale]?.title}
        </div>
        {isLangListOpen && <div className="absolute right-0 top-[34px] h-full w-[212px]">
            <div className={`layerLang mt-[14px] w-[212px] rounded-[12px] bg-white px-0 pb-[14px] pt-[14px] shadow-[0_2px_20px_rgba(0,0,0,0.08)]`} onMouseEnter={e => {
          setIsLangListMouseOver(true);
        }} onMouseLeave={e => {
          setIsLangListMouseOver(false);
        }}>
              <LangListButton langMap={langMap} setIsLangMouseOver={setIsLangMouseOver} setIsLangListMouseOver={setIsLangListMouseOver} setIsLangPopup={setIsLangPopup} />
            </div>
          </div>}
      </div>
    </>;
}